export const SCENARIO_STATUSES = {
  UPLOADED: "UPLOADED",
  PAYED: "PAYED",
  WRITING: "WRITING",
  DONE: "DONE",
};

export const ACCESS_LEVELS = {
  FREE: "FREE",
  REGISTERED: "REGISTERED",
  PART_ACCESS: "PART_ACCESS",
};

export const ratingOfSScenarioStatuses = {
  [SCENARIO_STATUSES.UPLOADED]: 1,
  [SCENARIO_STATUSES.PAYED]: 2,
  [SCENARIO_STATUSES.WRITING]: 3,
  [SCENARIO_STATUSES.DONE]: 4,
};

export const percentsOfSScenarioStatuses = {
  [SCENARIO_STATUSES.UPLOADED]: 25,
  [SCENARIO_STATUSES.PAYED]: 60,
  [SCENARIO_STATUSES.WRITING]: 80,
  [SCENARIO_STATUSES.DONE]: 100,
};

export const namesScenarioStatuses = {
  [SCENARIO_STATUSES.UPLOADED]: "Загруженно",
  [SCENARIO_STATUSES.PAYED]: "Читаем",
  [SCENARIO_STATUSES.WRITING]: "Пишем",
  [SCENARIO_STATUSES.DONE]: "Готово",
};

export const SCENARIO_TYPES = {
  SHORT_FILM: "SHORT_FILM",
  WEB_SERIES: "WEB_SERIES",
  FEATURE_FILM: "FEATURE_FILM",
  SERIES_PILOT: "SERIES_PILOT",
  SYNOPSIS: "SYNOPSIS",
  EDITORIAL_OPINION: "EDITORIAL OPINION",
  EDITORIAL_SUPPORT: "EDITORIAL_SUPPORT",
};

export const daysForDoneReview = {
  [SCENARIO_TYPES.SYNOPSIS]: 7,
  [SCENARIO_TYPES.SHORT_FILM]: 7,
  [SCENARIO_TYPES.WEB_SERIES]: 7,
  [SCENARIO_TYPES.FEATURE_FILM]: 14,
  [SCENARIO_TYPES.SERIES_PILOT]: 14,
  [SCENARIO_TYPES.EDITORIAL_OPINION]: 14,
  [SCENARIO_TYPES.EDITORIAL_SUPPORT]: 2,
};

export const GOOD_TYPES = {
  PLAY_LIST: "PLAY_LIST",
  SCENARIO_REVIEW: "SCENARIO_REVIEW",
  DEFEND_SCENARIO: "DEFEND_SCENARIO",
  COURSE: "COURSE",
};

export const GOOD_TYPES_LINKS = {
  PLAY_LIST: "/medialibrary",
  SCENARIO_REVIEW: "/account/reviews",
  COURSE: "/account/courses",
};

export const RECOMMENDED_PLAYLISTS_TYPES = {
  MAIN_PAGE: "MAIN_PAGE",
  SCENARIO_PAGE: "SCENARIO_PAGE",
  ACCOUNT_USER: "ACCOUNT_USER",
  ACCOUNT_PAYED: "ACCOUNT_PAYED",
  ACCOUNT_REVIEWS: "ACCOUNT_REVIEWS",
};

export const CONTESTS_FORMATS = {
  SHORT_FILM: "SHORT_FILM",
  FEATURE_FILM: "FEATURE_FILM",
  SERIES_PILOT: "SERIES_PILOT",
  SERIES: "SERIES",
  DOCUMENTARY: "DOCUMENTARY",
  ANIMATION: "ANIMATION",
  CREATIVE: "CREATIVE",
};

export const CONTESTS_FORMATS_NAMES = {
  [CONTESTS_FORMATS.SHORT_FILM]: "Короткий метр",
  [CONTESTS_FORMATS.SERIES]: "Сериал",
  [CONTESTS_FORMATS.SERIES_PILOT]: "Сериал (Пилот)",
  [CONTESTS_FORMATS.FEATURE_FILM]: "Полный метр",
  [CONTESTS_FORMATS.DOCUMENTARY]: "Документальный фильм",
  [CONTESTS_FORMATS.ANIMATION]: "Анимация",
  [CONTESTS_FORMATS.CREATIVE]: "Реклама",
};

export const CONTESTS_FORMATS_OPTIONS = [
  { value: CONTESTS_FORMATS.SHORT_FILM, label: "Короткий метр" },
  { value: CONTESTS_FORMATS.SERIES, label: "Сериал" },
  { value: CONTESTS_FORMATS.SERIES_PILOT, label: "Сериал (Пилот)" },
  { value: CONTESTS_FORMATS.FEATURE_FILM, label: "Полный метр" },
  { value: CONTESTS_FORMATS.DOCUMENTARY, label: "Документальный фильм" },
  { value: CONTESTS_FORMATS.ANIMATION, label: "Анимация" },
  { value: CONTESTS_FORMATS.CREATIVE, label: "Реклама" },
];
