import { Link } from "gatsby";
import * as React from "react";
import ArrowButton from "src/components/ArrowButton";
import Favorites from "src/components/Favorites";
import Typography from "src/components/Typography";
import { Contest as ContestType } from "src/types";
import styles from "./Contest.module.css";
import Description from "./Description";
import LinkWrapper from "./LinkWrapper";

interface Props extends ContestType {
  isTop?: boolean;
  onlyDate?: boolean;
  bigHeader?: boolean;
}

class Contest extends React.Component<Props> {
  public render() {
    const { props } = this;
    return (
      <div className={styles.wrapper}>
        <div className={styles.imgContainer}>
          <Favorites
            contestId={props._id}
            value={props.isFavorite}
            className={styles.favorites}
          />
          <Link
            to={`/contestId/${props.transcribeName}`}
            className={styles.imgWrapper}
          >
            <img src={props.img} alt="обложка конкурса" />
          </Link>
        </div>
        <div className={styles.name}>
          <Typography type={props.bigHeader ? "h3" : "h4"}>
            <h4>{props.name}</h4>
          </Typography>
        </div>
        <Description
          price={props.price}
          dateFinish={props.dateFinish}
          format={props.format}
          onlyDate={props.onlyDate}
          isRange={props.isRange}
        />
        <div>
          <Link to={`/contestId/${props.transcribeName}`}>
            <ArrowButton />
          </Link>
        </div>
      </div>
    );
  }
}

export default Contest;
