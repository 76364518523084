import * as React from "react";
import classes from "./Favorites.module.css";
interface Props {
  isFilled: boolean;
  disabled: boolean;
}

export const Icon: React.SFC<Props> = (props) => {
  let styles = {};
  if (props.isFilled) {
    styles = {
      fill: "#F06564",
    };
  }

  if (props.disabled) {
    styles = {
      fill: "gray",
      stroke: "#gray",
    };
  }

  return (
    <svg
      width="32"
      height="24"
      viewBox="0 -1 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={classes.transition}
        fill="none"
        stroke-alignment=""
        stroke="#F06564"
        // stroke-width="1.3"
        {...styles}
        d="M10.0013 16.9995C9.86973 17.0003 9.73927 16.9751 9.61743 16.9253C9.49559 16.8755 9.38478 16.8022 9.29134 16.7095L1.52134 8.92953C0.546698 7.94471 0 6.6151 0 5.22953C0 3.84396 0.546698 2.51435 1.52134 1.52953C2.5036 0.550039 3.83417 0 5.22134 0C6.60851 0 7.93908 0.550039 8.92134 1.52953L10.0013 2.60953L11.0813 1.52953C12.0636 0.550039 13.3942 0 14.7813 0C16.1685 0 17.4991 0.550039 18.4813 1.52953C19.456 2.51435 20.0027 3.84396 20.0027 5.22953C20.0027 6.6151 19.456 7.94471 18.4813 8.92953L10.7113 16.7095C10.6179 16.8022 10.5071 16.8755 10.3852 16.9253C10.2634 16.9751 10.1329 17.0003 10.0013 16.9995Z"
      />
    </svg>
  );
};
