import React from "react";
import { replacePostfix } from "src/components/DateFormater";
import Typography from "src/components/Typography";
import { CONTESTS_FORMATS_NAMES } from "src/consts";
import styles from "./Description.module.css";

interface Props {
  format: string;
  dateFinish: Date;
  price?: number;
  onlyDate?: boolean;
  isRange?: boolean;
}

export const Description: React.SFC<Props> = (props) => {
  const dateLabel = props.isRange ? "Дата подачи заявки:" : "Дата проведения";
  return (
    <div>
      <div className={styles.txtWrapper}>
        <dl>
          <dt>
            <Typography type="smallerText">{dateLabel}</Typography>{" "}
          </dt>
          <dd>
            <Typography
              type="boldText"
              style={{
                textTransform: "capitalize",
              }}
            >
              {props.isRange ? "До " : " "}
              {replacePostfix(
                new Date(props.dateFinish).toLocaleDateString("ru", {
                  month: "long",
                  day: props.isRange ? "numeric" : undefined,
                  year: "numeric",
                })
              )}
            </Typography>
          </dd>
        </dl>
        {!props.onlyDate ? (
          <>
            <dl>
              <dt>
                <Typography type="smallerText">Стоимость:</Typography>
              </dt>
              <dd>
                <Typography type="boldText">
                  {props.price ? `${props.price} Р` : "Бесплатно"}
                </Typography>
              </dd>
            </dl>
            <dl>
              <dt>
                <Typography type="smallerText">Формат:</Typography>
              </dt>
              <dd>
                <Typography type="boldText">
                  {CONTESTS_FORMATS_NAMES[props.format] || "Любой"}
                </Typography>
              </dd>
            </dl>
          </>
        ) : null}
      </div>
    </div>
  );
};
export default Description;
