import * as React from "react";
import Seo from "src/components/Seo";
import FavoritesContest from "src/routes/FavoritesContest";

const contests: React.SFC = () => {
  return (
    <>
      <Seo pageTitle="Избранные конкурсы" />
      <FavoritesContest />
    </>
  );
};

export default contests;
