import { Link } from "gatsby";
import * as React from "react";
import Api from "src/api";
import Button from "src/components/Button/Button";
import Contest from "src/components/ContestAnnounce";
import Typography from "src/components/Typography";
import { Contest as ContestType } from "src/types";
import classes from "./FavoritesContest.module.css";

type State = {
  announces: ContestType[];
  submitting: boolean;
};

class FavoritesContest extends React.Component<any, State> {
  public state = {
    announces: [],
    submitting: true,
  };

  public async componentDidMount() {
    await this.getData();
  }

  public render() {
    if (this.state.submitting) {
      return (
        <div className={classes.empty}>
          <Typography type="h3">Загружаем</Typography>
        </div>
      );
    }

    if (!this.state.announces?.length) {
      return (
        <div className={classes.empty}>
          <Typography type="h3">У вас еще нет избранных конкурсов</Typography>
          <Link to={"/contests"}>
            <Button>Посмотреть конкурсы</Button>
          </Link>
        </div>
      );
    }
    return (
      <div>
        <div>
          <Typography type="h5">Избранные конкурсы</Typography>
        </div>
        <div className={classes.mappedAnnounces}>
          {this.state.announces.map((curr) => (
            <Contest key={curr._id} {...curr} onlyDate />
          ))}
        </div>
      </div>
    );
  }

  private async getData() {
    this.setState({
      submitting: true,
    });
    const res = await Api.getFavoritesContests();

    this.setState({
      announces: res.data,
      submitting: false,
    });
  }
}

export default FavoritesContest;
