import { navigate } from "gatsby";
import * as React from "react";
import { connect } from "react-redux";
import Api from "src/api";
import { User } from "src/types";
import classes from "./Favorites.module.css";
import { Icon } from "./Icon";

interface ConnectStateProps {
  user: User;
}
interface Props extends ConnectStateProps {
  value: boolean;
  contestId: string;
  className?: string;
}

interface S {
  submitting: boolean;
  value: boolean;
}

class Favorites extends React.Component<Props, S> {
  public state = {
    value: this.props.value,
    submitting: false,
  };

  public render() {
    const actionTitle = this.state.value
      ? "Убрать из избранного"
      : "Добавить в избранное";

    return (
      <div
        onClick={this.handleClick}
        title={actionTitle}
        role="button"
        className={[this.props.className, classes.wrapper].join(" ")}
      >
        <Icon isFilled={this.state.value} disabled={this.state.submitting} />
      </div>
    );
  }

  private handleClick = async () => {
    if (!this.props.user?.email) {
      await navigate("/register", {
        state: {
          modal: true,
          noScroll: true,
          closeTo:
            typeof window !== `undefined` ? window.location.pathname : "/",
        },
      });
      return;
    }

    try {
      await this.changeValue();
    } catch (error) {
      this.setState({ submitting: false });
      alert("Что-то пошло не так");
    }
  };

  private changeValue = async () => {
    this.setState({ submitting: true });

    if (this.state.value) {
      await Api.removeFavoriteContest(this.props.contestId);
      this.setState({ value: false, submitting: false });
      return;
    }

    await Api.setFavoriteContest(this.props.contestId);
    this.setState({ value: true, submitting: false });
  };
}

const mapStateToProps = (state) => ({
  user: state.user.user,
});

export default connect<ConnectStateProps>(mapStateToProps)(Favorites);
